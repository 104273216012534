.av-tenant-user-table {
    display: flex;
    flex-direction: column;
    gap: calc(1.6em / 2);

    &__card {
        width: 100%;
        padding: 1.5em 1.0em;
        border-radius: 0.8em;
        font-size: 0.85em;
        line-height: 1.4;

        .x-row-inner {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            align-content: center;

            .x-col {
                flex: 1;
                word-wrap: break-word;
            }
        }
    }

    &__header {
        background-color: #1789FB1a;
        color: #1789FB;
        font-weight: 800;
    }

    &__item {
        border: 1px solid #DFE3E7;
        color: rgb(20, 49, 75);
    }

    .av-custom-button {
        border-radius: 0.35em;
        width: fit-content;
        margin-left: auto;
    }
}