@import "../../../variables.scss";

.av-dashboard-header {
  background-color: white;
  color: #728392;
  width: 100%;
  padding: 4px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #DFE3E7;
  position: sticky;
  z-index: 1;
  top: 0;

  .hamburger {
    display: block;
  }

  img {
    margin-left: 12px;
    max-width: 150px;
    object-fit: contain;
  }

  .route-map {
    margin-left: 8px;
    line-height: 100%;
    text-align: start;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      cursor: pointer;
      transition: color 0.3s linear;

      &:hover {
        color: rgb(15, 113, 211);
      }

      i {
        display: inline-block;
        font-size: 12px;
        margin: 0 4px;

        &:hover {
          color: #728392;
        }
      }
    }
  }

  .notification {
    margin-left: auto;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    text-align: center;
    line-height: 34px;
    background-color: #DFE3E7;
    cursor: pointer;
  }
}

@media (min-width: 980px) {
  .av-dashboard-header {
    padding: 18px 24px;

    .hamburger {
      display: none;
    }

    .route-map {
      margin-left: 64px;
    }
  }
}

.dashboard_header {
  height: 20px;
  padding: 1.2rem 2rem;
  box-shadow: 0 3px 6px rgba(220, 220, 220, 0.16),
    0 3px 6px rgba(191, 191, 191, 0.23);
  display: flex;
  align-items: center;
  color: black;
  font-size: 11px;
  background-color: white;
}
.dashboard_header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dashboard_header_inner_section {
  display: flex;
  align-items: center;
  gap: 2em;
}
.dashboard_header_notification_section {
  padding: 8px;
  border-radius: 50%;
  background: grey;
  position: relative;
}

.dashboard_header_notification_section span {
  padding: 2px 4px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: -3px;
  right: -3px;
  font-size: 10px;
}
