

.tableSkeletonrow {
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 1fr 1fr;
  grid-template-rows: 100px;
  gap: 10px;
}

.skeleton {
  background: #e1e1e1;
  border-radius: 4px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
