@import "../../variables.scss";

.av-dashboard-layout {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #F7F8FA;

  &-desktop, &-mobile {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &-desktop {
    display: none;
  }

  &-mobile {
    display: flex;
    flex-direction: column;

    &__sidebar-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 2;

      &-enter {
        opacity: 0;

        &-active {
          animation: fadeIn 0.5s forwards;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          animation: fadeOut 0.5s forwards;
        }
      }
    }

    &__sidebar-content {
      height: 100%;
    }

    .av-page-content {
      flex: 1;
    }
  }
}

.slide-in {
  animation: slideInLeft 0.5s forwards;
}

.slide-out {
  animation: slideOutLeft 0.5s forwards;
}

.slide-in-left {
  animation: slideInLeft 0.5s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.5s forwards;
}

.slide-in-right {
  animation: slideInRight 0.5s forwards;
}

.slide-out-right {
  animation: slideOutRight 0.5s forwards;
}

@media (min-width: $breakpoint-laptop) {
  .av-dashboard-layout {
    &-mobile {
      display: none;
    }

    &-desktop {
      display: flex;
      flex-direction: column;

      &__content {
        flex: 1;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;

        .outlet {
          flex: 1;
          height: 100%;
          overflow-y: auto;
        }
      }
    }
  }
}