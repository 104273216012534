.av-custom-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    min-height: 35px;
    flex-wrap: wrap;
    border: 1px;
    overflow: hidden;
    border: 1px solid #DFE3E7;
    border-radius: 3px;
    font-size: 13px;
    color: #728393;

    label {
        display: block;
        width: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1em;
        align-items: center;
        align-content: center;
        min-height: 35px;
        background-color: #DFE3E7;
    }

    select {
        background-color: transparent;
        flex: 1;
        font-size: 1.2em;
        border: none;
        outline: none;
        color: #728393;
        caret-color: #728393;
        line-height: 120%;
        padding: 12px;
    }
}