.av-custom-input {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  min-height: 50px;
  border: 1px;
  overflow: hidden;
  border: 1px solid #dfe3e7;
  border-radius: 3px;
  font-size: 13px;
  color: #728393;
  margin-bottom: 0.5em;
  transition: all 200ms ease-in-out;

  label {
    display: block;
    width: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 12px;
    // font-weight: bold;
    font-size: 1em;
    align-items: center;
    align-content: center;
    min-height: 35px;
    background-color: #dfe3e7;
    transition: all 200ms ease-in-out;
  }

  input {
    background-color: transparent;
    width: 100%;
    font-size: 1.2em;
    border: none;
    outline: none;
    color: #728393;
    caret-color: #728393;
    line-height: 120%;
  }
}

.av-custom-input-error {
  border-color: #d61a19;

  label {
    background: #d61a19;
    color: white;
  }
}

.av-custom-input__error {
  text-transform: capitalize;
  font-size: 0.75rem;
  color: #d61a19 !important;

  &-hidden {
    display: none;
  }
}
